import { isDefined } from "@clipboard-health/util-ts";
import { useSkillsAssessmentFeatureFlags } from "@src/appV2/SkillsAssessment/api/useSkillsAssessmentFeatureFlags";
import { useSkillsAssessments } from "@src/appV2/SkillsAssessment/api/useSkillsAssessments";
import { AssessmentType } from "@src/appV2/SkillsAssessment/types";
import type { Worker } from "@src/appV2/Worker/api/types";

interface Props {
  agent?: Worker;
}

export function useIsScheduledAssessmentAvailableForOnboarding(props: Props) {
  const { agent } = props;
  const { isSkillsAssessmentOnboardingEnabled, skillsAssessmentConfig } =
    useSkillsAssessmentFeatureFlags();

  const { data: skillAssessments, isSuccess: isSkillAssessmentsSuccess } = useSkillsAssessments(
    {
      workerId: agent?.userId ?? "",
      qualificationNames: skillsAssessmentConfig.availableQualificationNames,
    },
    {
      enabled: isSkillsAssessmentOnboardingEnabled && isDefined(agent?.userId),
      staleTime: 0,
      cacheTime: 0,
    }
  );

  const isScheduledAssessmentAvailableForOnboarding =
    isSkillsAssessmentOnboardingEnabled &&
    isSkillAssessmentsSuccess &&
    isDefined(skillAssessments) &&
    skillAssessments.data?.length > 0 &&
    skillAssessments.included?.some(
      (included) =>
        included.type === "assessment-status" &&
        included.attributes.type === AssessmentType.SCHEDULED
    );

  return {
    isScheduledAssessmentAvailableForOnboarding,
  };
}
