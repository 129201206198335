import { useLocalStorage } from "@src/appV2/lib/utils";
import { WorkTypeOption } from "@src/appV2/redesign/Onboarding/components/InterestedWorkType";
import { useIsWorkTypeOnboardingEnabled } from "@src/appV2/redesign/Onboarding/useIsWorkTypeOnboardingEnabled";

export function useSelectedWorkType() {
  const [selectedOption] = useLocalStorage<WorkTypeOption | undefined>(
    "interested-work-type", // Same key used in InterestedWorkType.tsx
    undefined
  );
  const isWorkTypeOnboardingEnabled = useIsWorkTypeOnboardingEnabled();

  return {
    selectedWorkType: selectedOption,
    isPermanentPlacement:
      selectedOption === WorkTypeOption.PERMANENT_PLACEMENTS && isWorkTypeOnboardingEnabled,
    isFlex:
      selectedOption === WorkTypeOption.FLEX ||
      selectedOption === undefined ||
      !isWorkTypeOnboardingEnabled, // Default to FLEX if not set
    isBoth: selectedOption === WorkTypeOption.PERMANENT_AND_FLEX && isWorkTypeOnboardingEnabled,
  };
}
